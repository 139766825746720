import {AxiosPromise} from "axios";

import httpClient from "~api/httpClient";
import {CustomerSegmentQuery} from "~types/customers/customerSegment";
import {
  MessengerConversationItem,
  MessengerConversationsCountType,
  MessengerMessage,
  MessengerMessageLinkMetadata,
  MessengerMessageMediaAttachment,
} from "~types/messenger";
import {IncomingMessageNotificationMode} from "~types/notifications";

export interface FetchConversationsParams {
  unread?: boolean;
  isEscalated?: boolean;
  q?: string;
  page?: number;
  perPage?: number;
}

export interface FetchConversationParams {
  centsCustomerId: number;
}

export interface FetchConversationMessagesParams {
  page?: number;
  perPage?: number;
}

export interface SendingMessageDetails {
  content: string;
}

export interface MessengerPaginationOutput<T> {
  records: T[];
  meta: {
    total: number;
    currentPage: number;
    hasNextPage: boolean;
  };
}

export interface SendBulkMessageBody {
  query?: CustomerSegmentQuery;
  centsCustomerIds?: number[];
  messageDetails: {
    content: string;
  };
}

export interface MessengerFailedSendingReason {
  centsCustomerId: number;
  reason?: string;
}
export interface SendBulkMessageResponse {
  successful: number;
  failed: MessengerFailedSendingReason[];
}

export type FetchConversationsResponse =
  MessengerPaginationOutput<MessengerConversationItem>;

export type FetchChatBotEnabledResponse = {
  chatBotEnabled: boolean;
};

export type FetchBusinessConfigurationResponse = {
  disableSmsBot: boolean;
  incMsgNtfMode: IncomingMessageNotificationMode;
  smsEscalationEmail: string | null;
  smsEscalationPhoneNumber: string | null;
};

export type UpdateConfigurationChatBotEnabledBody = {
  chatBotEnabled: boolean;
};

export type UpdateBusinessConfigurationBody = {
  disableSmsBot?: boolean;
  incMsgNtfMode?: IncomingMessageNotificationMode;
  smsEscalationEmail?: string | null;
  smsEscalationPhoneNumber?: string | null;
};

export type UpdateConversationChatBotEnabledBody = {
  chatBotEnabled: boolean;
  centsCustomerId: number;
};

export type UpdateChatBotEnabledResponse = {
  chatBotEnabled: boolean;
};

export type FetchConversationMessagesResponse =
  MessengerPaginationOutput<MessengerMessage>;

export type FetchMessengerConnectionUrlResponse = {url: string};

export const fetchMessengerConnectionUrl =
  (): AxiosPromise<FetchMessengerConnectionUrlResponse> => {
    return httpClient({
      method: "GET",
      url: "websocket-connection/messenger",
    });
  };

export const fetchConversation = (
  centsCustomerId: string
): AxiosPromise<MessengerConversationItem> => {
  return httpClient({
    method: "GET",
    url: `messenger/conversations/${centsCustomerId}/details`,
  });
};

export const fetchConversations = ({
  params,
}: {
  params: FetchConversationsParams;
}): AxiosPromise<FetchConversationsResponse> => {
  return httpClient({
    method: "GET",
    url: "messenger/conversations",
    params,
  });
};

export const fetchChatBotEnabled = (): AxiosPromise<FetchChatBotEnabledResponse> => {
  return httpClient({
    method: "GET",
    url: `messenger/configuration/chat-bot-enabled`,
  });
};

export const fetchBusinessConfiguration =
  (): AxiosPromise<FetchBusinessConfigurationResponse> => {
    return httpClient({
      method: "GET",
      url: `messenger/configuration`,
    });
  };

export const updateBusinessConfiguration = (
  body: UpdateBusinessConfigurationBody
): AxiosPromise<FetchBusinessConfigurationResponse> => {
  return httpClient({
    method: "PATCH",
    url: `messenger/configuration`,
    data: body,
  });
};

export const updateConfigurationChatBotEnabled = (
  body: UpdateConfigurationChatBotEnabledBody
): AxiosPromise<FetchChatBotEnabledResponse> => {
  return httpClient({
    method: "PATCH",
    url: `messenger/configuration/chat-bot-enabled`,
    data: body,
  });
};

export const updateConversationChatBotEnabled = (
  body: UpdateConversationChatBotEnabledBody
): AxiosPromise<FetchChatBotEnabledResponse> => {
  return httpClient({
    method: "PATCH",
    url: `messenger/conversations/chat-bot-enabled`,
    data: body,
  });
};

export const fetchUnreadConversationsCount =
  (): AxiosPromise<MessengerConversationsCountType> => {
    return httpClient({
      method: "GET",
      url: "messenger/conversations/unread-count",
    });
  };

export const fetchEscalationCount = (): AxiosPromise<MessengerConversationsCountType> => {
  return httpClient({
    method: "GET",
    url: "messenger/conversations/escalations/count",
  });
};

export const fetchConversationMessages = (
  centsCustomerId: string,
  params: FetchConversationMessagesParams
): AxiosPromise<FetchConversationMessagesResponse> => {
  return httpClient({
    method: "GET",
    url: `messenger/conversations/${centsCustomerId}`,
    params,
  });
};

export const markConversationAsRead = (
  centsCustomerId: string
): AxiosPromise<MessengerConversationItem> => {
  return httpClient({
    method: "PATCH",
    url: `messenger/conversations/${centsCustomerId}/read`,
  });
};

export const markConversationAsResolved = (
  centsCustomerId: number | string
): AxiosPromise<MessengerConversationItem> => {
  return httpClient({
    method: "PATCH",
    url: `messenger/conversations/${centsCustomerId}/resolve`,
  });
};

export const sendSmsMessage = (centsCustomerId: string, body: FormData): AxiosPromise => {
  return httpClient({
    method: "POST",
    url: `messenger/conversations/${centsCustomerId}/message`,
    data: body,
  });
};

export const sendBulkMessage = (
  body: FormData
): AxiosPromise<SendBulkMessageResponse> => {
  return httpClient({
    method: "POST",
    url: "messenger/conversations/bulk-message",
    data: body,
  });
};

export const fetchMessageMedia = (
  messageId: MessengerMessage["messageId"]
): AxiosPromise<MessengerMessageMediaAttachment[]> => {
  return httpClient({
    method: "GET",
    url: `messenger/messages/${messageId}/media`,
  });
};

export const fetchMessageLinkMetadata = (
  url: string
): AxiosPromise<MessengerMessageLinkMetadata> => {
  return httpClient.get(`messenger/messages/link-metadata`, {
    params: {
      url,
    },
  });
};
